import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import api from "./../../../../api";
import moment from "moment";
import Table from "./../../../../components/share/Table";
import Pagination from "../../../../components/app/Pagination";
import Popup from "../../../../components/share/InsightUI/Popup";
import { withRouter } from "react-router-dom";
import { Button } from "./../../../../components/share/InsightUI";
import { AuthContext } from "./../../../../components/auth/FirebaseAuthContext";
import { UserDataContext } from "./../../../../components/app/UserData";
import { SegmentsContext } from "./../../../../context/Segments";
import {
  captureExceptionToSentry,
  ellipsize,
  parseUrl,
} from "./../../../../utils";
import LoaderWithText from "./../../../../components/share/LoaderWithText";
import { fetchSizeBasedSegments } from "./../../../../lib/firebase/segment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./style.scss";
import useClickOutside from "../../../../hooks/useClickOutside";
import { ERRORS_MAPPED, DEFAULT_ERROR } from "../../../../constants/error";
import Alert from "../../../../components/share/Alert";
import { AiOutlineCopy } from "react-icons/ai";
import Notification from "../../../../components/share/InsightUI/Notification";

function Pages(props) {
  const { location } = props;
  const listType = props.reportType || props.type;
  const reportId = props.match.params.reportId;
  const profileId = props.match.params.profileId;
  const { authUser } = useContext(AuthContext);
  const {
    startDate,
    endDate,
    appliedFilters,
    isInitialFiltersLoaded,
    isApiServerLoading,
  } = useContext(UserDataContext);
  const { selectedSegments, setSelectedSegments, isInitialSegmentsLoaded } =
    useContext(SegmentsContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [step, setStep] = useState(1);
  const [screenSegments, setScreenSegments] = useState([]);
  const [queryId, setQueryId] = useState(null);
  const [key, setKey] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingTotal, setIsLoadingTotal] = useState(false);
  const [isDependenciesLoaded, setIsDependenciesLoaded] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isTotalLoaded, setIsTotalLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [analysisOptions, setAnalysisOptions] = useState({
    url: "",
    segment: "",
    title: false,
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoadingScreenSizes, setisLoadingScreenSizes] = useState(false);
  const [isScreenSizesLoaded, setIsScreenSizesLoaded] = useState(false);

  // Live clickmap pop up
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupIndex, setPopupIndex] = useState();
  const liveClickMapPopupRef = useRef();

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });
  const [isCopied, setIsCopied] = useState(false);
  const searchInputRef = useRef(null);

  const handleClickView = (e) => {
    const data = JSON.parse(e.currentTarget.dataset["payload"]);
    const [visitorId, sessionNumber] = data.exampleSessionId.split(":");
    props.history.push(
      `/profile/${profileId}/replay/session/${visitorId}/${sessionNumber}`,
      {
        replayOptions: {
          activeTab: "analytics",
          analyticsMode: "clickmap-links",
          url: data.url,
          segments: selectedSegments,
        },
      }
    );
  };

  const handleOpenPopup = (e) => {
    const rowIndex = parseInt(e.currentTarget.dataset["index"]);

    setIsPopupVisible(true);
    setPopupIndex(rowIndex);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const columns = useMemo(() => {
    let c = [
      {
        Header: "",
        accessor: "exampleSessionId",
        Cell: (param) => {
          const { row } = param;
          // Check if the pop-up should be visible for a specific row
          const isRowWithPopup = row.index === popupIndex;

          if (row.original.exampleSessionId) {
            return (
              <span>
                <Button
                  size="small"
                  variant="primary"
                  onClick={handleClickView}
                  data-payload={JSON.stringify(row.original)}
                >
                  View Analytics
                </Button>
              </span>
            );
          } else {
            return (
              <span>
                {row.index !== 0 && (
                  <div className="popup-container">
                    <Button
                      size="small"
                      variant="secondary"
                      onClick={handleOpenPopup}
                      data-payload={JSON.stringify(row.original)}
                      data-index={row.index}
                    >
                      View Analytics
                    </Button>
                    {isPopupVisible && isRowWithPopup && (
                      <Popup
                        popupRef={liveClickMapPopupRef}
                        handleClosePopup={handleClosePopup}
                        description={
                          "Click Map is available on the live website only, please click the link to open the page and open Insightech Chrome Extension to view the Click Map"
                        }
                        url={row.original.url}
                      />
                    )}
                  </div>
                )}
              </span>
            );
          }
        },
      },
      {
        Header: "Page URL",
        accessor: "url",
        Cell: (param) => {
          const { value } = param;
          if (value) {
            const formattedValue = parseUrl(value);
            return (
              <span
                className="ml-2"
                data-tooltip-id="page-tooltip"
                data-tooltip-content={value}
                data-raw-url={value} // store the raw value for clipboard copy
              >
                {ellipsize(
                  formattedValue
                    ? `${formattedValue.pathname}${formattedValue.search}${formattedValue.hash}`
                    : value,
                  50
                )}
              </span>
            );
          } else {
            return (
              <span className="ml-2">
                <strong>(Grand Total)</strong>
              </span>
            );
          }
        },
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The count of unique website visits from users, that visit each page."
          >
            Sessions
          </span>
        ),
        accessor: "sessions",
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The total number of page loads or URL loads for each URL shown."
          >
            Page Views
          </span>
        ),
        accessor: "pageviews",
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The percentage of pageviews where at least one click event was recorded. (Pageviews with Clicks / Total Pageviews)."
          >
            Click Rate %
          </span>
        ),
        accessor: "clickRate",
        Cell: ({ value }) => {
          return `${Math.round(value * 100)}%`;
        },
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The median depth that users vertically scroll to, as a percentage of the total page height, when loading each page."
          >
            Median Scroll Depth %
          </span>
        ),
        accessor: "medianScrollDepth",
        Cell: ({ value }) => {
          return `${Math.round(value * 100)}%`;
        },
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The median time spent for each pageview from start to finish, calculated in seconds and displayed in the format hh:mm:ss."
          >
            Median Duration
          </span>
        ),
        accessor: "medianDuration",
        Cell: ({ value }) => {
          return moment
            .duration(value, "milliseconds")
            .format("HH:mm:ss", { trim: false });
        },
      },
      {
        Header: (
          <span
            className="pageHeader"
            data-tooltip-id="page-tooltip"
            data-tooltip-content="The percentage of sessions that visit a URL, and convert within the same session (Converted Sessions / Total Sessions)."
          >
            Assisted Conversion %
          </span>
        ),
        accessor: "assistedConversion",
        Cell: ({ value }) => {
          return `${Math.round(value * 100 * 100) / 100}%`;
        },
      },
    ];

    // Conditionally add columns based on `listType`
    if (listType === "landing_page_list") {
      c.push(
        {
          Header: (
            <span
              className="pageHeader"
              data-tooltip-id="page-tooltip"
              data-tooltip-content="The total revenue from sessions that visit a URL, and convert within the same session."
            >
              Assisted Revenue $
            </span>
          ),
          accessor: "revenue",
          Cell: ({ value }) => (value ? `$${value.toFixed(2)}` : "$0.00"),
        },
        {
          Header: (
            <span
              className="pageHeader"
              data-tooltip-id="page-tooltip"
              data-tooltip-content="The average revenue from sessions that visit a URL, and convert within the same session (Total Revenue / Total Converted Sessions)."
            >
              Avg Conversion Revenue $
            </span>
          ),
          accessor: "avgConvRevenue",
          Cell: ({ value }) => (value ? `$${value.toFixed(2)}` : "$0.00"),
        }
      );
    }

    if (analysisOptions.title) {
      c.splice(2, 0, {
        Header: "Page Title",
        accessor: "title",
      });
    }
    return c;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSegments, analysisOptions, listType, page, isPopupVisible]);

  const handleApiError = (error, loaderToggle) => {
    if (api.isCancel(error)) {
      return;
    }
    const errMsg = error.response?.data?.result;
    if (ERRORS_MAPPED[errMsg]) {
      setAlert({
        show: true,
        type: "danger",
        message: ERRORS_MAPPED[errMsg],
        count: alert.count + 1,
      });
    } else {
      setAlert({
        show: true,
        type: "danger",
        message: DEFAULT_ERROR,
        count: alert.count + 1,
      });
      captureExceptionToSentry(error);
    }

    loaderToggle(false);
  };

  const readPageList = ({ queryId, key, page, pageSize }) => {
    api.cancel("readPageList");
    api
      .readPageList(authUser, {
        profileId,
        queryId,
        key,
        page,
        pageSize,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readPageList({ queryId, key, page, pageSize });
        } else if (res.data.status === 200) {
          setIsLoadingData(false);
          setIsDataLoaded(true);
          setTotalRows(res.data.data.totalRows);
          setData(res.data.data.records || []);
        } else {
          setIsLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const readPageTotal = ({ queryId, key }) => {
    api.cancel("readPageTotal");
    api
      .readPageTotal(authUser, {
        profileId,
        queryId,
        key,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readPageTotal({ queryId, key });
        } else if (res.data.status === 200) {
          setIsLoadingTotal(false);
          setIsTotalLoaded(true);
          setTotal(res.data.data.records || []);
        } else {
          setIsLoadingTotal(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingTotal));
  };

  const fetchPageList = () => {
    setIsLoadingData(true);
    const segmentsArray = Object.keys(selectedSegments).map((key) => {
      return key;
    });
    api.cancel("queryPageList");
    api.cancel("readPageList");

    // Base params object
    const params = {
      profileId,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      filter: JSON.stringify(appliedFilters),
      pageURL: JSON.stringify({
        operator: "contains",
        values: [analysisOptions.url],
      }),
      title: analysisOptions.title,
      segments: JSON.stringify(segmentsArray),
      sort: "pageviews-desc",
    };

    // Add pageType parameter if listType is landing_page_list
    if (listType === "landing_page_list") {
      params.pageType = "landing";
    }

    api
      .queryPageList(authUser, params)
      .then((res) => {
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readPageList({ queryId, key, page, pageSize });
          setQueryId(queryId);
          setKey(key);
          setAlert({
            show: false,
            type: "",
            message: "",
            count: 0,
          });
        } else {
          setIsLoadingData(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingData));
  };

  const fetchPageTotal = () => {
    // Todo : do not change in cas of pagination
    setIsLoadingTotal(true);
    const segmentsArray = Object.keys(selectedSegments).map((key) => {
      return key;
    });
    api.cancel("queryPageTotal");
    api.cancel("readPageTotal");
    api
      .queryPageTotal(authUser, {
        profileId,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        filter: JSON.stringify(appliedFilters),
        pageURL: JSON.stringify({
          operator: "contains",
          values: [analysisOptions.url],
        }),
        segments: JSON.stringify(segmentsArray),
      })
      .then((res) => {
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readPageTotal({ queryId, key });
        } else {
          setIsLoadingTotal(false);
          setAlert({
            show: true,
            type: "danger",
            message: DEFAULT_ERROR,
            count: alert.count + 1,
          });
        }
      })
      .catch((error) => handleApiError(error, setIsLoadingTotal));
  };

  const handleClickNext = () => {
    props.history.push(window.location, { analysisOptions });
  };

  const handleCheckSegment = (e) => {
    setAnalysisOptions({
      ...analysisOptions,
      segment: e.target.value,
    });
  };

  const handleChangeUrl = (e) => {
    const value = e.target.value;
    setAnalysisOptions({
      ...analysisOptions,
      url: value,
    });
  };

  // Click the "search" button to search the page URL
  const handleSearchUrl = () => {
    const value = searchInputRef.current.value;
    setAnalysisOptions({
      ...analysisOptions,
      url: value,
    });
  };

  // Pressing the "Enter" key on the search input
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchUrl();
    }
  };

  const handleChangeTitle = (e) => {
    const value = e.target.value;
    setAnalysisOptions({
      ...analysisOptions,
      title: value,
    });
  };

  // Handler for copying table data to the clipboard
  const handleCopyTableData = async () => {
    try {
      const table = document.querySelector("#table-pages");

      // Extract the headers from the table
      const headers = [];
      table.querySelectorAll("thead th").forEach((header, index) => {
        if (index === 0) return; // Skip the first column
        headers.push(header.innerText);
      });

      // Prepare the headers for clipboard copy
      const headerRow = headers.join("\t");

      // Extract rows and get raw URLs for clipboard copy
      const rows = table.querySelectorAll("tbody tr");
      const tableData = [];
      rows.forEach((row) => {
        const rowData = [];
        row.querySelectorAll("td").forEach((cell, index) => {
          if (index === 0) return; // Skip the first column
          const rawUrl = cell.querySelector("[data-raw-url]");

          if (rawUrl) {
            rowData.push(rawUrl.getAttribute("data-raw-url")); // Use the raw URL value
          } else {
            rowData.push(cell.innerText); // Fallback to cell text for other columns
          }
        });
        tableData.push(rowData.join("\t"));
      });

      // Combine headers and rows
      const tableText = [headerRow, ...tableData].join("\n");
      await navigator.clipboard.writeText(tableText);

      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      setIsCopied(false);
    }
  };

  const handleCloseNotification = () => {
    setIsCopied(false);
  };

  // Dismiss the pop up when click outside
  useClickOutside(liveClickMapPopupRef, handleClosePopup);

  // Main useEffect used for unmounting
  useEffect(() => {
    return () => {
      api.cancel("queryPageTotal");
      api.cancel("readPageTotal");
      api.cancel("queryPageList");
      api.cancel("readPageTotal");
    };
  }, []);

  // Pagination
  useEffect(() => {
    if (queryId && key) {
      setIsLoadingData(true);
      readPageList({ queryId, key, page, pageSize });
      // Grand Total is the same on all pages
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    if (
      !isApiServerLoading &&
      isInitialized &&
      profileId &&
      step === 2 &&
      appliedFilters &&
      isInitialFiltersLoaded &&
      isInitialSegmentsLoaded &&
      !isDependenciesLoaded
    ) {
      setIsDependenciesLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialized,
    profileId,
    step,
    isInitialFiltersLoaded,
    isInitialSegmentsLoaded,
    isDependenciesLoaded,
    isApiServerLoading,
  ]);

  useEffect(() => {
    if (profileId && !location.state && !isScreenSizesLoaded && step === 1) {
      setisLoadingScreenSizes(true);
      fetchSizeBasedSegments({ profileId }).then((res) => {
        const sortedSegments = res.sort((segment1, segment2) => {
          if (segment1.name < segment2.name) {
            return -1;
          }
          if (segment1.name > segment2.name) {
            return 1;
          }
          return 0;
        });
        setisLoadingScreenSizes(false);
        setIsScreenSizesLoaded(true);
        setScreenSegments(sortedSegments);
        if (res.length) {
          setAnalysisOptions({
            ...analysisOptions,
            segment: sortedSegments[0].id,
          });
        }
      });
    }

    if (isDependenciesLoaded && profileId && isInitialized && step === 2) {
      fetchPageList();
      fetchPageTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileId,
    step,
    appliedFilters,
    analysisOptions,
    selectedSegments,
    startDate,
    endDate,
    isInitialized,
    isDependenciesLoaded,
    listType,
  ]);

  useEffect(() => {
    if (!!reportId) {
      setStep(2);
      setIsInitialized(true);
    }
    if (
      location.state &&
      location.state.analysisOptions &&
      step === 1 &&
      !isInitialized
    ) {
      setAnalysisOptions(location.state.analysisOptions);
      if (location.state.analysisOptions.segment) {
        setSelectedSegments({
          ...selectedSegments,
          [location.state.analysisOptions.segment]: true,
        });
      }
      setStep(2);
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (step === 1) {
      document
        .getElementById("content-wrapper")
        .classList.add("page-analysis-step1");
      props.setHasTitle(false);
      props.setIsQuickFilterVisible(false);
      props.setIsQuickReportVisible(false);
    }
    if (step === 2) {
      props.setHasTitle(true);
      props.setIsQuickFilterVisible(true);
      props.setIsQuickReportVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      {step === 1 && !isLoadingScreenSizes && isScreenSizesLoaded && (
        <>
          <div className="row mb-5">
            <div className="col-12">
              <h1 className="h3 mb-4 mt-2 text-gray-800 text-center">
                Start analysing{" "}
                {listType === "landing_page_list" ? "landing" : "all"} pages
              </h1>
              <p className="text-gray-500 text-center">
                Start exploring a specific page or skip to view all tracked
                pages
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-md-1">
              <div className="row mb-5">
                <div className="col-5">
                  Enter page URL (leave blank to view all pages):
                </div>
                <div className="col-7">
                  <input
                    onChange={handleChangeUrl}
                    type="text"
                    className="form-control form-control-sm"
                    value={analysisOptions.url}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-5">
                  Select device segment you want to view
                </div>
                <div className="col-7">
                  {screenSegments.map((segment, index) => {
                    return (
                      <label className="device-option" key={index}>
                        <input
                          onChange={handleCheckSegment}
                          checked={analysisOptions.segment === segment.id}
                          type="radio"
                          name="view"
                          value={segment.id}
                        />{" "}
                        {segment.name}
                      </label>
                    );
                  })}
                  <label className="device-option">
                    <input
                      onChange={handleCheckSegment}
                      checked={analysisOptions.segment === ""}
                      type="radio"
                      name="view"
                      value=""
                    />{" "}
                    All devices view
                  </label>
                </div>
              </div>
              <hr />
              <p className="text-right">
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleClickNext}
                >
                  Continue
                </Button>
              </p>
            </div>
          </div>
        </>
      )}

      {step === 1 && isLoadingScreenSizes && (
        <LoaderWithText
          text="Loading Screen Sizes"
          style={{ margin: "30px 0" }}
        />
      )}
      {step === 2 && (
        <Alert show={alert.show} type={alert.type} message={alert.message} />
      )}
      {step === 2 && (
        <div className="row">
          <div className="col-12 mb-3">
            <div className="card shadow">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="search-url form-inline">
                    <div className="search-area">
                      <input
                        defaultValue={analysisOptions.url}
                        ref={searchInputRef} // Attach ref to the input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Search for page URL"
                        onKeyDown={handleKeyDown} // Add keydown event handler
                      />
                      <Button
                        size="x-small"
                        variant="secondary"
                        className="search-button"
                        onClick={handleSearchUrl}
                      >
                        search
                      </Button>
                    </div>
                    <select
                      className="form-control form-control-sm"
                      onChange={handleChangeTitle}
                    >
                      <option value={false}>Show page URL only</option>
                      <option value={true}>Show page URL and title</option>
                    </select>
                  </div>
                  <div>
                    <button className="copy-icon" onClick={handleCopyTableData}>
                      <AiOutlineCopy
                        data-tooltip-id="page-tooltip"
                        data-tooltip-content="Copy page table with full urls"
                      />
                    </button>
                  </div>
                </div>

                <Table
                  hScroll
                  id="table-pages"
                  data={total.concat(data)}
                  columns={columns}
                  isLoading={isLoadingData || isLoadingTotal}
                  isDataLoaded={isDataLoaded && isTotalLoaded}
                  loadingText={
                    <>
                      <i className="fa fa-spinner spin"></i> Loading Pages...
                    </>
                  }
                />
                {!isLoadingData && (
                  <Pagination
                    activePage={page + 1}
                    totalRows={totalRows}
                    pageSize={pageSize}
                    changeHandler={(pageNum, pageSize) => {
                      setPage(pageNum - 1);
                      setPageSize(pageSize);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isCopied && (
        <Notification
          message="Successfully copied page list data!"
          onClose={handleCloseNotification}
        />
      )}
      <ReactTooltip id="page-tooltip" className="tooltip-lg" />
    </>
  );
}

Pages.propTypes = {};
Pages.defaultProps = {};

export default withRouter(Pages);
